import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "login" */ '../views/Welcome.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    props: true,
    component: () => import(/* webpackChunkName: "create-new-password" */ '../views/auth/PasswordRecovery.vue'),
  },
  {
    path: '/password-recovery/:token',
    name: 'CreateNewPassword',
    props: true,
    component: () => import(/* webpackChunkName: "create-new-password" */ '../views/CreateNewPassword.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/restaurant/:id',
    name: 'Restaurant',
    props: true,
    component: () => import(/* webpackChunkName: "restaurant" */ '../views/Restaurant.vue'),
  },
  {
    path: '/additions/:restaurant_id/:id/:cart_product_id?',
    name: 'Additions',
    props: true,
    component: () => import(/* webpackChunkName: "additions" */ '../views/Additions.vue'),
  },




  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/user/Orders.vue'),
  },
  {
    path: '/order/:id',
    name: 'Order',
    props: true,
    component: () => import(/* webpackChunkName: "order" */ '../views/user/Order.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/user/Profile.vue'),
  },
  {
    path: '/payment/payment-success/:id?',
    name: 'PaymentSuccess',
    props: true,
    component: () => import(/* webpackChunkName: "payment-success" */ '../views/PaymentSuccess.vue'),
  },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Welcome' && window.localStorage.getItem('fastcat_user_token')) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router
