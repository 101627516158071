import Api from '@/service/RestApi';

export default {
  async fetchTypesOfCategory({ commit, state }) {
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchTypesOfCategory(state.selectedCategory);
      if (statusCode === 200) {
        commit('setTypes', data);
      }
    } catch (e) {

    }
  },
  async fetchRestaurants({ commit, state }) {
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchRestaurants(state.selectedCategory, state.address.city_id, state.selectedType, state.address.latitude, state.address.longitude);
      if (statusCode === 200) {
        commit('setRestaurants', data);
      }
    } catch (e) {

    }
  },
  async fetchRestaurantsClosed({ commit, state }) {
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchRestaurantsClosed(state.selectedCategory, state.address.city_id, state.selectedType, state.address.latitude, state.address.longitude);
      if (statusCode === 200) {
        commit('setRestaurantsClosed', data);
      }
    } catch (e) {

    }
  },
  async fetchRestaurantMenu({ commit, state }, { restaurantId }) {
    commit('startFetching', 'restaurantMenu');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchRestaurantMenu(restaurantId);
      if (statusCode === 200) {
        commit('setRestaurantMenu', {id: restaurantId, data});
      }
    } catch (e) {

    }
    commit('endFetching', 'restaurantMenu');
  },
  async fetchRestaurantMenuItem({ commit, state }, { itemId }) {
    commit('startFetching', 'restaurantMenuItem');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchRestaurantMenuItem(itemId);
      if (statusCode === 200) {
        commit('setRestaurantMenuItem', {id: itemId, data});
      }
    } catch (e) {

    }
    commit('endFetching', 'restaurantMenuItem');
  },
  async fetchMyOrders({ commit, state }) {
    commit('startFetching', 'fetchMyOrders');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchMyOrders();
      if (statusCode === 200) {
        commit('setOrders', data);
      }
    } catch (e) {

    }
    commit('endFetching', 'fetchMyOrders');
  },
  async fetchMyOrder({ commit, state }, { id }) {
    commit('startFetching', 'fetchMyOrder');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchMyOrder(id);
      if (statusCode === 200) {
        commit('setOrderDetails', data);
      }
    } catch (e) {

    }
    commit('endFetching', 'fetchMyOrder');
  },
  async fetchUser({ commit, state }) {
    commit('startFetching', 'user');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchUser();
      if (statusCode === 200) {
        commit('setUser', data);
      }
    } catch (e) {

    }
    commit('endFetching', 'user');
  },
  async fetchCart({ commit, state }) {
    commit('startFetching', 'cart');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.fetchCart();
      if (statusCode === 200) {
        commit('setCart', data);
      }
    } catch (e) {

    }
    commit('endFetching', 'cart');
  },
  async reCalcCart({ commit, state }) {
    commit('startFetching', 'reCalcCart');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postReCalcCart();
      if (statusCode === 200) {
        commit('setCart', data);
      }
    } catch (e) {

    }
    commit('endFetching', 'reCalcCart');
  },
  async registerUser({ commit, state }, { form }) {
    commit('startFetching', 'registerUser');
    try {
      const postData = {...form};
      postData.name = postData.first_name + ' ' + postData.last_name;
      delete postData.first_name;
      delete postData.last_name;
      postData.phone = postData.phone.replaceAll(' ', '').match(/.{1,3}/g).join(' ');
      const { data: {data, statusCode, message = ''} } = await Api.postRegisterUser(postData);
      // if (statusCode === 200) {
        // return {data, statusCode, message};
      // }
      commit('endFetching', 'registerUser');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'registerUser');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async loginUser({ commit, state }, { form }) {
    commit('startFetching', 'loginUser');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postLogin(form);
      if (statusCode === 200) {
        Api.http.$setUserToken(data.token);
        commit('setToken', data.token);
        commit('setUser', data.user);
        window.localStorage.setItem('fastcat_user_token', data.token);
      }
      commit('endFetching', 'loginUser');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'loginUser');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async userLogout({ commit, state}) {
    Api.http.$clearUserToken();
    commit('setToken', null);
    commit('setUser', {});
    window.localStorage.removeItem('fastcat_user_token');
  },
  async setNewPassword({ commit, state }, { form }) {
    commit('startFetching', 'setNewPassword');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postSetNewPassword(form);
      commit('endFetching', 'setNewPassword');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'setNewPassword');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async resetPassword({ commit, state }, { form }) {
    commit('startFetching', 'resetPassword');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postResetPassword(form);
      commit('endFetching', 'resetPassword');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'resetPassword');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartAddProduct({ commit, state }, { form }) {
    commit('startFetching', 'cartAddProduct');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartAddProduct(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartAddProduct');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartAddProduct');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartEditProduct({ commit, state }, { form }) {
    commit('startFetching', 'cartEditProduct');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartEditProduct(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartEditProduct');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartEditProduct');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartAddDiscount({ commit, state }, { form }) {
    commit('startFetching', 'cartAddDiscount');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartAddDiscount(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartAddDiscount');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartAddDiscount');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartDeleteProduct({ commit, state }, { form }) {
    commit('startFetching', 'cartDeleteProduct');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartDeleteProduct(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartDeleteProduct');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartDeleteProduct');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartClear({ commit, state }) {
    commit('startFetching', 'cartClear');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartClear();
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartClear');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartClear');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async cartDeleteDiscount({ commit, state }) {
    commit('startFetching', 'cartDeleteDiscount');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartDiscountDelete();
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'cartDeleteDiscount');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'cartDeleteDiscount');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async getAddressByAddress({ commit, state, dispatch }, { form }) {
    commit('startFetching', 'getAddressByAddress');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.getAddressByAddress(form);
      if (statusCode === 200) {
        commit('setAddress', data);
        dispatch('fetchRestaurants');
        dispatch('fetchRestaurantsClosed');
      }
      commit('endFetching', 'getAddressByAddress');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'getAddressByAddress');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async setCartAddress({ commit, state }, { form }) {
    commit('startFetching', 'setCartAddress');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartAddress(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'setCartAddress');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'setCartAddress');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async setCartNotes({ commit, state }, { form }) {
    commit('startFetching', 'setCartNotes');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartNotes(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'setCartNotes');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'setCartNotes');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async setCartDeliveryType({ commit, state }, { form }) {
    commit('startFetching', 'setCartDeliveryType');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postCartDeliveryType(form);
      if (statusCode === 200) {
        commit('setCart', data);
      }
      commit('endFetching', 'setCartDeliveryType');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'setCartDeliveryType');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  async placeOrder({ commit, state }, { form }) {
    commit('startFetching', 'placeOrder');
    try {
      const { data: {data, statusCode, message = ''} } = await Api.postPlaceOrder(form);
      commit('endFetching', 'placeOrder');
      return {data, statusCode, message};
    } catch (e) {
      commit('endFetching', 'placeOrder');
      return {data: {}, statusCode: 500, message: 'Internal error'};
    }
  },
  selectType({commit, dispatch}, { typeId }) {
    commit('setSelectedType', typeId);
    dispatch('fetchRestaurants');
    dispatch('fetchRestaurantsClosed');
  },
};
