export default {
  fetching: state => {
    return state.fetching;
  },
  isMobile: state => {
    return state.isMobile;
  },
  showDownloadFromStore: state => {
    return state.showDownloadFromStore;
  },
  showDrawer: state => {
    return state.showDrawer;
  },
  showSearchAddress: state => {
    return state.showSearchAddress;
  },
  showCart: state => {
    return state.showCart;
  },
  categories: state => {
    return state.categories;
  },
  selectedCategory: state => {
    return state.selectedCategory;
  },
  types: state => {
    return state.types;
  },
  selectedType: state => {
    return state.selectedType;
  },
  restaurants: state => {
    return state.restaurants;
  },
  restaurantsClosed: state => {
    return state.restaurantsClosed;
  },
  restaurant: state => id => {
    let rest = state.restaurants.find(a => a.id === id);
    if (!rest) {
      rest = state.restaurantsClosed.find(a => a.id === id);
    }
    return rest ?? {};
  },
  restaurantsMenu: state => {
    return state.restaurantMenu;
  },
  restaurantMenuItems: state => {
    return state.restaurantMenuItem;
  },
  user: state => {
    return state.user;
  },
  token: state => {
    return state.token;
  },
  isLogged: state => {
    return !!state.user.id;
  },
  cart: state => {
    return state.cart;
  },
  orders: state => {
    return state.orders;
  },
  orderDetails: state => {
    return state.orderDetails;
  },
  address: state => {
    return state.address;
  },
};
