const getOS = () => {
  let OSName = 'unknown';
  if (navigator.platform.indexOf('Win') !== -1) OSName = 'windows';
  if (navigator.platform.indexOf('Mac') !== -1) OSName = 'macos';
  if (navigator.platform.indexOf('X11') !== -1) OSName = 'unix';
  if (navigator.platform.indexOf('Linux') !== -1) OSName = 'linux';
  if (navigator.platform.indexOf('iPhone') !== -1) OSName = 'ios';
  if (navigator.platform.indexOf('Android') !== -1) OSName = 'android';
  return OSName;
};

const isOS = (os) => {
  return os === getOS();
};

export default {
  install(Vue, options) {
    Vue.prototype.$getOS = getOS;
    Vue.prototype.$isOS = isOS;
  }
}
