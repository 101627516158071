import { http, getApiPath } from '@/plugins/Axios';

export default {
  http,
  fetchTypesOfCategory(categoryId) {
    return http.get(getApiPath(`types-category/${categoryId}`));
  },
  fetchRestaurants(categoryId, cityId, typeId, lat, lng) {
    return http.get(getApiPath(`partners-open/${categoryId}/${cityId}/${typeId ? typeId : 'all'}/${lat}/${lng}`));
  },
  fetchRestaurantsClosed(categoryId, cityId, typeId, lat, lng) {
    return http.get(getApiPath(`partners-close/${categoryId}/${cityId}/${typeId ? typeId : 'all'}/${lat}/${lng}`));
  },
  fetchRestaurantMenu(restaurantId) {
    return http.get(getApiPath(`partner-menu/${restaurantId}`));
  },
  fetchRestaurantMenuItem(itemId) {
    return http.get(getApiPath(`product/${itemId}`));
  },
  fetchUser() {
    return http.get(getApiPath(`user`));
  },
  fetchCart() {
    return http.post(getApiPath(`cart/get`));
  },
  fetchMyOrdersInProgress() {
    return http.get(getApiPath(`my-orders-in-progress`));
  },
  fetchMyOrders() {
    return http.get(getApiPath(`my-orders`));
  },
  fetchMyOrder(id) {
    return http.get(getApiPath(`order/${id}`));
  },
  postReCalcCart() {
    return http.post(getApiPath(`cart/recalc`));
  },
  postCartAddProduct(data) {
    return http.post(getApiPath(`cart/add`), data);
  },
  postCartEditProduct(data) {
    return http.post(getApiPath(`cart/edit`), data);
  },
  postCartDeleteProduct(data) {
    return http.post(getApiPath(`cart/delete`), data);
  },
  postCartAddress(data) {
    return http.post(getApiPath(`cart/address`), data);
  },
  postCartNotes(data) {
    return http.post(getApiPath(`cart/notes`), data);
  },
  postCartDeliveryType(data) {
    return http.post(getApiPath(`cart/delivery-type`), data);
  },
  postPlaceOrder(data) {
    return http.post(getApiPath(`order/place`), data);
  },
  postCartClear() {
    return http.post(getApiPath(`cart/clear`));
  },
  postCartAddDiscount(data,) {
    return http.post(getApiPath(`cart/discount`), data);
  },
  postCartDiscountDelete() {
    return http.post(getApiPath(`cart/discount/delete`));
  },
  postRegisterUser(data) {
    return http.post(getApiPath(`register`), data);
  },
  postLogin(data) {
    return http.post(getApiPath(`login`), data);
  },
  getAddressByGeocodes(data) {
    return http.post(getApiPath(`get-address/by-geocodes`), data);
  },
  getAddressByAddress(data) {
    return http.post(getApiPath(`get-address/by-address`), data);
  },
  postUserDeleteAccount(data) {
    return http.post(getApiPath(`user/delete-account`), data);
  },
  postSetNewPassword(data) {
    return http.post(getApiPath(`user/password-recovery/set-password`), data);
  },
  postResetPassword(data) {
    return http.post(getApiPath(`user/password-recovery`), data);
  },
}
