export default {
  isMobile: false,
  showDownloadFromStore: false,
  showDrawer: false,
  showSearchAddress: false,
  showCart: false,
  categories: [],
  selectedCategory: '92a26a48-8cd9-497c-9618-240c8e60e66b',
  types: [],
  selectedType: null,
  restaurants: [],
  restaurantsClosed: [],
  restaurantMenu: {},
  restaurantMenuItem: {},
  address: {
    city_id: '93231149-35bd-4035-9909-78b561394f1c',
    latitude: 53.127018,
    longitude: 18.0055623,
    street: 'Gimnazjalna',
    house_nr: '6',
    apartment_nr: '',
    postcode: '85-007',
    city: 'Bydgoszcz',
  },
  user: {},
  token: null,
  cart: {},
  orders: [],
  orderDetails: {},
  fetching: {
    restaurantMenu: false,
    cart: false,
    registerUser: false,
    loginUser: false,
    restaurantMenuItem: false,
    cartAddProduct: false,
    reCalcCart: false,
    setCartAddress: false,
    setCartNotes: false,
    placeOrder: false,
    fetchMyOrder: false,
  },
};
