import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
import 'animate.css/animate.min.css';
import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import detect_os from "@/plugins/DetectOS";
import axios from "@/plugins/Axios";
import constants from "@/plugins/Constants";
import moment from "@/plugins/Moment";

Vue.config.productionTip = false

Vue.use(constants);
Vue.use(detect_os);
Vue.use(axios);
Vue.use(moment);
Vue.use(SequentialEntrance);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 5000,
});

new Vue({
  created() {
    if (this.$isOS('ios') || this.$isOS('android')) {
      this.$store.commit('setIsMobile', true);
      this.$store.commit('setShowDownloadFromStore', true);
    }
    const token = window.localStorage.getItem('fastcat_user_token');
    if (token) {
      this.$http.$setUserToken(token);
      this.$store.commit('setToken', token);
      this.$store.dispatch('fetchUser');
    }
    this.$store.dispatch('fetchTypesOfCategory');
    this.$store.dispatch('fetchRestaurants');
    this.$store.dispatch('fetchRestaurantsClosed');
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
