import Axios from "axios";

const accessToken = 'MZWKqCrtzThiI9VtN5NWYysuvt1lUvD72zys9aeYGjPujhLEaQxOjQ1c32nNcaSR';

export const getAssetLink = (path = '') => {
  // return `${window.location.protocol}//${window.location.host}/${path}`;
  return `https://fastcat.pl/${path}`;
};

export const getApiPath = (path = '') => {
  return getAssetLink(`api/v2/${path}`);
}

export const setUserToken = (userToken) => {
  // console.log(`${accessToken}#${userToken}`);
  Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}#${userToken}`;
}

export const clearUserToken = () => {
  // console.log(`${accessToken}#${userToken}`);
  Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

Axios.$setUserToken = setUserToken;
Axios.$clearUserToken = clearUserToken;

// Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

// const token = document.head.querySelector('meta[name="csrf-token"]');
//
// if (token) {
//   Axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//   console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }
//
// Axios.$csrfToken = token.content;

export const http = Axios;

export default {
  install(Vue, options) {
    Vue.prototype.$asset = getAssetLink;
    Vue.prototype.$apiUrl = getApiPath;
    Vue.prototype.$http = Axios;
  },
}
