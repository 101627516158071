
export default {
  startFetching(state, payload) {
    state.fetching = {...state.fetching, [payload]: true};
  },
  endFetching(state, payload) {
    state.fetching = {...state.fetching, [payload]: false};
  },
  setIsMobile(state, payload) {
    state.isMobile = payload;
  },
  setShowDownloadFromStore(state, payload) {
    state.showDownloadFromStore = payload;
  },
  setShowDrawer(state, payload) {
    state.showDrawer = payload;
  },
  setShowSearchAddress(state, payload) {
    state.showSearchAddress = payload;
  },
  setShowCart(state, payload) {
    state.showCart = payload;
  },
  switchShowSearchAddress(state) {
    state.showSearchAddress = !state.showSearchAddress;
  },
  setTypes(state, payload) {
    state.types = [...payload];
  },
  setSelectedType(state, payload) {
    if (state.selectedType === payload) {
      state.selectedType = null;
    } else {
      state.selectedType = payload;
    }
  },
  setRestaurants(state, payload) {
    state.restaurants = [...payload];
  },
  setRestaurantsClosed(state, payload) {
    state.restaurantsClosed = [...payload];
  },
  setRestaurantMenu(state, payload) {
    state.restaurantMenu[payload.id] = [...payload.data];
  },
  setRestaurantMenuItem(state, payload) {
    state.restaurantMenuItem[payload.id] = {...payload.data};
  },
  setUser(state, payload) {
    state.user = {...payload};
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setCart(state, payload) {
    state.cart = {...payload};
  },
  setOrders(state, payload) {
    state.orders = [...payload];
  },
  setOrderDetails(state, payload) {
    state.orderDetails[payload.id] = {...payload};
  },
  setAddress(state, payload) {
    state.address = {...payload};
  }
};
