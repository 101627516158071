const Constants = {
  regulations: 'https://fastcat.pl/fastcat-regulamin-sg.pdf',
  privacy_policy: 'https://fastcat.pl/privacy-policy.pdf',
};

export default {
  install(Vue, options) {
    Vue.prototype.$constants = Constants;
  }
}
